import { ApolloProvider } from '@wirechunk/apollo-client';
import {
  DirectionProvider,
  ErrorCollectorContextProvider,
  RouterProvider,
  TooltipPrimitiveProvider,
} from '@wirechunk/ui';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { lazy } from 'react';
import { apolloClient } from './apollo-client.ts';
import { AnalyticsContextProvider } from './components/analytics-context-provider.tsx';
import { ErrorBoundary } from './components/error-boundary/error-boundary.tsx';
import { SessionUserProvider } from './components/session-user-provider.tsx';
import { ThemeProvider } from './components/theme-provider.tsx';
import { ToastContextProvider } from './components/toast-context-provider.tsx';
import { router } from './router.tsx';

import 'primeicons/primeicons.css';
import './css/theme.css';
import './css/index.css';

const LazyStripeElementsProvider = window.wirechunk.stripePK
  ? lazy(() => import('./components/stripe-elements-provider.tsx'))
  : ({ children }: PropsWithChildren) => children;

export const App: FunctionComponent = () => (
  <ErrorBoundary showDialog>
    <TooltipPrimitiveProvider delayDuration={200}>
      <DirectionProvider dir="ltr">
        <ThemeProvider>
          <ToastContextProvider>
            <ApolloProvider client={apolloClient}>
              <ErrorCollectorContextProvider>
                <LazyStripeElementsProvider>
                  <SessionUserProvider>
                    <AnalyticsContextProvider>
                      <RouterProvider router={router} />
                    </AnalyticsContextProvider>
                  </SessionUserProvider>
                </LazyStripeElementsProvider>
              </ErrorCollectorContextProvider>
            </ApolloProvider>
          </ToastContextProvider>
        </ThemeProvider>
      </DirectionProvider>
    </TooltipPrimitiveProvider>
  </ErrorBoundary>
);
