import { useSuspenseQuery } from '@wirechunk/apollo-client';
import { PublicSiteDocument } from '@wirechunk/lib/shared-queries/public-site-query.generated.ts';
import { SiteContext } from '@wirechunk/ui';
import type { FunctionComponent, PropsWithChildren } from 'react';

const { siteId } = window.wirechunk;

// When the ApolloClient store is reset, the data for this query is null until it's re-fetched, and for this reason we cannot
// mount a SiteContext outside of the router. If the entire router un-mounts, the site does not render.
export const CurrentSiteProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { data } = useSuspenseQuery(PublicSiteDocument, {
    variables: { id: siteId },
  });
  return <SiteContext value={data.publicSite}>{children}</SiteContext>;
};
