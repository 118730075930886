import type { InMemoryCacheConfig, NormalizedCacheObject } from '@wirechunk/apollo-client';
import { ApolloClient, from, HttpLink, InMemoryCache, split } from '@wirechunk/apollo-client';
import { onError } from '@wirechunk/apollo-client/link/error';
import { RetryLink } from '@wirechunk/apollo-client/link/retry';
import { GraphQLWsLink } from '@wirechunk/apollo-client/link/subscriptions';
import { getMainDefinition } from '@wirechunk/apollo-client/utilities';
import apolloClientPossibleTypes from '@wirechunk/lib/apollo-client-possible-types.ts';
import { Kind, OperationTypeNode } from 'graphql';
import { createClient } from 'graphql-ws';
import { visualPreviewAuthToken } from './initial-search-params.ts';

const cacheConfig: InMemoryCacheConfig = {
  // Here we can specify the unique keys of types that don't have an "id" field if we want objects of these types
  // to be stored in Apollo Client's global normalized cache. Otherwise objects without an "id" field are embedded
  // directly within the data returned by the API.
  typePolicies: {
    UserCourseProgress: {
      keyFields: ['userId', 'courseId'],
    },
    UserPlanProgress: {
      keyFields: ['userId', 'planId'],
    },
  },
  possibleTypes: apolloClientPossibleTypes.possibleTypes,
};

const retryLink = new RetryLink({
  delay: {
    initial: 20,
  },
  attempts: {
    // We should make a reasonable number of attempts but no too many because, while retrying, the user
    // would not know that something is failing.
    max: 10,
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(
        `[GraphQL error]: ${message}, Location: ${JSON.stringify(locations || [])}, Path: ${(
          path || []
        ).join()}`,
      );
    });
  }

  if (networkError) {
    console.error(`[Network error (${networkError.name})]: ${networkError.message}`);
  }
});

const httpLink = new HttpLink({
  uri: '/api',
  headers: {
    ...(visualPreviewAuthToken && {
      authorization: `Bearer ${visualPreviewAuthToken}`,
    }),
  },
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: `${import.meta.env.PROD ? 'wss' : 'ws'}://${window.location.host}/api`,
    retryAttempts: 10,
    shouldRetry: () => true,
    connectionParams: {
      ...(visualPreviewAuthToken && {
        authorization: `Bearer ${visualPreviewAuthToken}`,
      }),
    },
    onNonLazyError: (error) => {
      console.error('onNonLazyError [graphql-ws]', error);
    },
  }),
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === Kind.OPERATION_DEFINITION &&
      definition.operation === OperationTypeNode.SUBSCRIPTION
    );
  },
  wsLink,
  httpLink,
);

// On the frontend, we don't have defaultOptions because we want the default fetchPolicy of 'cache-first'.
// Any query that requires certain data freshness guarantees can define its own fetch policy.
export const apolloClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link: from([retryLink, errorLink, splitLink]),
  cache: new InMemoryCache(cacheConfig).restore(window.wirechunk.initState),
  credentials: 'same-origin',
  assumeImmutableResults: true,
});
