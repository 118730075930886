import * as Sentry from '@sentry/react';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { environment } from '../../env.ts';
import { PaddedContent } from '../PaddedContent/PaddedContent.tsx';

export { captureException } from '@sentry/react';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.captureConsoleIntegration({
      levels: ['error'],
      handled: true,
    }),
    Sentry.browserTracingIntegration({
      // Sentry doesn't do a good job of capturing and nesting spans. Only the first few fetch requests are
      // captured, and they're placed under the "pageload" transaction.
      traceFetch: false,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  environment,
  release: import.meta.env.VITE_APP_VERSION,
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});

const fallback = (
  <PaddedContent className="m-3">
    <h1 className="text-lg mt-1 mb-3">Error</h1>
    <p className="font-bold mb-1">Sorry, some unhandled error occurred.</p>
  </PaddedContent>
);

type ErrorBoundaryProps = PropsWithChildren<{
  showDialog?: boolean;
}>;

export const ErrorBoundary: FunctionComponent<ErrorBoundaryProps> = ({ showDialog, children }) => (
  <Sentry.ErrorBoundary fallback={fallback} showDialog={showDialog}>
    {children}
  </Sentry.ErrorBoundary>
);
