import type { ContextData } from '@wirechunk/schemas/context-data/context-data';
import type { ConfirmationAction, LessonCompletionMode } from './graphql-api-enums.ts';
import type { Component, Styles } from './mixer/types/components.ts';

// Paths starting with double underscores are reserved for internal use.
export const visualPreviewPath = '__visual-preview';

// The name of the URL parameter that contains the visual preview auth token.
export const visualPreviewAuthTokenParam = 'wirechunkPreviewAuthToken';

// The name of the URL parameter that contains the object type to preview.
export const visualPreviewObjectTypeParam = 'wirechunkPreviewObjectType';

export const visualPreviewObjectTypes = [
  'Component',
  'ComponentPropsSetup',
  'CustomFieldsForm',
  'Form',
  'FormConfirmationMessageComponents',
  'FormStep',
  'FormTemplate',
  'FormTemplateConfirmationMessageComponents',
  'FormTemplateStep',
  'Layout',
  'LayoutTemplate',
  'Lesson',
  'Page',
  'PageTemplate',
  'PageTemplatePropsSetup',
  'PageVersion',
  'Stage',
] as const;

export type VisualPreviewObjectType = (typeof visualPreviewObjectTypes)[number];

// Sent in objects from the preview iframe to the parent.
export const previewIFrameReadyMessageType = 'preview-iframe-ready';

// Sent in objects from the parent to the preview iframe.
export const previewIFrameUpdateMessageType = 'preview-iframe-update';

// Sent from the preview iframe to the parent.
export type PreviewIFrameReadyMessage = {
  type: typeof previewIFrameReadyMessageType;
};

// Sent from the parent to the preview iframe.
export type PreviewIFrameUpdateMessageSpec<OT, O> = {
  type: typeof previewIFrameUpdateMessageType;
  objectType: OT;
  object: O;
};

export const isPreviewIFrameUpdateMessageSpec = (
  data: unknown,
): data is PreviewIFrameUpdateMessageSpec<unknown, unknown> =>
  typeof data === 'object' &&
  data !== null &&
  'type' in data &&
  data.type === previewIFrameUpdateMessageType &&
  'objectType' in data &&
  'object' in data;

export type ObjectTypeMap = {
  Component: PreviewComponentObject;
  ComponentPropsSetup: PreviewComponentPropsSetupObject;
  CustomFieldsForm: PreviewCustomFieldsFormObject;
  Form: PreviewFormObject;
  FormConfirmationMessageComponents: PreviewFormConfirmationMessageComponentsObject;
  FormStep: PreviewFormStepObject;
  FormTemplate: PreviewFormTemplateObject;
  FormTemplateConfirmationMessageComponents: PreviewFormTemplateConfirmationMessageComponentsObject;
  FormTemplateStep: PreviewFormTemplateStepObject;
  Layout: PreviewLayoutObject;
  LayoutTemplate: PreviewLayoutTemplateObject;
  Lesson: PreviewLessonObject;
  Page: PreviewPageObject;
  PageTemplate: PreviewPageTemplateObject;
  PageTemplatePropsSetup: PreviewPageTemplatePropsSetupObject;
  PageVersion: PreviewPageVersionObject;
  Stage: PreviewStageObject;
};

export type ObjectForType<T extends VisualPreviewObjectType> = ObjectTypeMap[T];

export type VisualPreviewMessageForObjectType<T extends VisualPreviewObjectType> = {
  type: typeof previewIFrameUpdateMessageType;
  objectType: T;
  object: ObjectForType<T>;
};

// Object types.

export type PreviewComponentObject = {
  components: Component[];
  customProps: ContextData | null;
};

export type PreviewComponentPropsSetupObject = {
  components: Component[];
};

export type PreviewCustomFieldsFormObject = {
  components: Component[];
};

export type PreviewFormObject = {
  id: string;
  components: Component[];
  confirmationAction: ConfirmationAction;
  confirmationMessageComponents: Component[] | null;
  confirmationRedirectUrl: string | null;
  confirmationRedirectParameters: Array<{
    id: string;
    parameter: string;
    componentName: string;
  }>;
  steps: Array<{ id: string; components: Component[] }>;
};

export type PreviewFormConfirmationMessageComponentsObject = {
  components: Component[];
};

export type PreviewFormStepObject = {
  components: Component[];
};

export type PreviewFormTemplateObject = {
  id: string;
  components: Component[];
  confirmationAction: ConfirmationAction;
  confirmationMessageComponents: Component[] | null;
  confirmationRedirectUrl: string | null;
  confirmationRedirectParameters: Array<{
    id: string;
    parameter: string;
    componentName: string;
  }>;
  steps: Array<{ id: string; components: Component[] }>;
};

export type PreviewFormTemplateConfirmationMessageComponentsObject = {
  components: Component[];
};

export type PreviewFormTemplateStepObject = {
  components: Component[];
};

export type PreviewLayoutObject = {
  // TODO: bodyStyles
  components: Component[];
};

export type PreviewLayoutTemplateObject = {
  // TODO: bodyStyles
  components: Component[];
};

export type PreviewLessonObject = {
  lesson: {
    id: string;
    components: Component[];
  };
  course: {
    id: string;
    lessonCompletionMode: LessonCompletionMode;
  };
};

export type PreviewPageObject = {
  title: string;
  metaTitle: string | null;
  metaDescription: string | null;
  metaRobots: string[] | null;
  components: Component[];
  bodyStyles: Styles;
  props: ContextData | null;
};

export type PreviewPageTemplateObject = {
  title: string;
  metaTitle: string | null;
  metaDescription: string | null;
  metaRobots: string[] | null;
  components: Component[];
  bodyStyles: Styles;
  props: ContextData | null;
};

export type PreviewPageTemplatePropsSetupObject = {
  components: Component[];
};

export type PreviewPageVersionObject = {
  components: Component[];
};

export type PreviewStageObject = {
  sequenceId: string;
  components: Component[];
};
