import { ToastContext } from '@wirechunk/ui';
import type { ToastMessage } from 'primereact/toast';
import { Toast } from 'primereact/toast';
import type { FunctionComponent, PropsWithChildren, ReactNode } from 'react';
import { Fragment, useCallback, useMemo, useRef } from 'react';

export const ToastContextProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const ref = useRef<Toast>(null);
  const showToast = useCallback(
    (severity: ToastMessage['severity'], message: string | ReactNode, summary?: string) => {
      const toastMessage: ToastMessage = {
        severity,
        life: 6000,
        contentClassName: 'gap-1',
        content: (
          <div className="p-toast-message-text">
            {summary && <span className="p-toast-summary">{summary}</span>}
            <div className="p-toast-detail">{message}</div>
          </div>
        ),
      };
      if (ref.current) {
        ref.current.show(toastMessage);
      } else {
        setTimeout(() => {
          ref.current?.show(toastMessage);
        }, 0);
      }
    },
    [],
  );

  const value = useMemo<ToastContext>(
    () => ({
      toastError: (message, heading) => {
        showToast('error', message, heading);
      },
      toastSuccess: (message, heading) => {
        showToast('success', message, heading);
      },
      toastWarn: (message, heading) => {
        showToast('warn', message, heading);
      },
    }),
    [showToast],
  );

  return (
    <Fragment>
      <Toast ref={ref} />
      <ToastContext value={value}>{children}</ToastContext>
    </Fragment>
  );
};
