export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    ApiToken: ['PersonalApiToken', 'PlatformApiToken'],
    ApplyCustomPromoCodeResult: ['ApplyCustomPromoCodeSuccessResult', 'InternalError'],
    ArchiveComponentResult: ['ArchiveComponentSuccessResult', 'UserError'],
    BuilderComponent: ['BuiltInComponent', 'Component', 'RemoteComponent'],
    CourseVersionOrLessonVersion: ['CourseVersion', 'LessonVersion'],
    CreateAdminUserResult: ['CreateAdminUserSuccessResult', 'UserError'],
    CreateComponentResult: ['CreateComponentSuccessResult', 'UserError'],
    CreateCourseResult: ['CreateCourseSuccessResult', 'UserError'],
    CreateCustomTableEditorFormVersionResult: [
      'AuthorizationError',
      'CreateCustomTableEditorFormVersionSuccessResult',
      'UserError',
    ],
    CreateExtensionResult: ['CreateExtensionSuccessResult', 'InternalError', 'UserError'],
    CreateExtensionVersionResult: [
      'AuthorizationError',
      'CreateExtensionVersionSuccessResult',
      'InternalError',
      'UserError',
    ],
    CreateLayoutResult: ['CreateLayoutSuccessResult', 'UserError'],
    CreateLayoutTemplateResult: [
      'AuthorizationError',
      'CreateLayoutTemplateSuccessResult',
      'UserError',
    ],
    CreateLessonResult: ['CreateLessonSuccessResult', 'UserError'],
    CreateLessonUserResult: ['CreateLessonUserSuccessResult', 'UserError'],
    CreatePersonalApiTokenResult: ['CreatePersonalApiTokenSuccessResult', 'UserError'],
    CreatePlatformApiTokenResult: ['CreatePlatformApiTokenSuccessResult', 'UserError'],
    CreatePlatformResult: ['CreatePlatformSuccessResult', 'UserError'],
    CreateProductResult: ['CreateProductSuccessResult', 'UserError'],
    CreateSequenceResult: ['CreateSequenceSuccessResult', 'UserError'],
    CreateSiteResult: [
      'AuthorizationError',
      'CreateSiteSuccessResult',
      'InternalError',
      'UserError',
    ],
    CreateSiteTemplateLayoutTemplatesResult: [
      'CreateSiteTemplateLayoutTemplatesSuccessResult',
      'UserError',
    ],
    CreateSiteTemplatePageTemplatesResult: [
      'CreateSiteTemplatePageTemplatesSuccessResult',
      'UserError',
    ],
    CreateSiteTemplateResult: ['CreateSiteTemplateSuccessResult', 'UserError'],
    CreateUserResult: [
      'AuthorizationError',
      'CreateUserSuccessResult',
      'InternalError',
      'UserError',
    ],
    DeleteExtensionVersionResult: [
      'AuthorizationError',
      'DeleteExtensionVersionSuccessResult',
      'UserError',
    ],
    DeleteSiteTemplateLayoutTemplatesResult: [
      'DeleteSiteTemplateLayoutTemplatesSuccessResult',
      'UserError',
    ],
    DeleteSiteTemplatePageTemplatesResult: [
      'DeleteSiteTemplatePageTemplatesSuccessResult',
      'UserError',
    ],
    DuplicateComponentResult: ['DuplicateComponentSuccessResult', 'InternalError', 'UserError'],
    DuplicateCourseResult: ['DuplicateCourseSuccessResult', 'UserError'],
    DuplicateLessonResult: ['DuplicateLessonSuccessResult', 'UserError'],
    DuplicateSequenceResult: ['DuplicateSequenceSuccessResult', 'UserError'],
    EditComponentResult: ['EditComponentSuccessResult', 'UserError'],
    EditCourseResult: ['EditCourseSuccessResult', 'UserError'],
    EditCustomTableResult: ['EditCustomTableSuccessResult', 'UserError'],
    EditExtensionResult: ['AuthorizationError', 'EditExtensionSuccessResult', 'UserError'],
    EditFormStepPositionResult: [
      'AuthorizationError',
      'EditFormStepPositionSuccessResult',
      'InternalError',
      'UserError',
    ],
    EditFormTemplateEmailTemplateResult: [
      'EditFormTemplateEmailTemplateSuccessResult',
      'UserError',
    ],
    EditFormTemplateResult: ['AuthorizationError', 'EditFormTemplateSuccessResult', 'UserError'],
    EditLayoutResult: ['EditLayoutSuccessResult', 'UserError'],
    EditLayoutTemplateResult: [
      'AuthorizationError',
      'EditLayoutTemplateSuccessResult',
      'UserError',
    ],
    EditLessonResult: ['EditLessonSuccessResult', 'UserError'],
    EditLessonUserResult: ['EditLessonUserSuccessResult', 'UserError'],
    EditOrgPrimaryUserResult: ['EditOrgPrimaryUserSuccessResult', 'UserError'],
    EditPageResult: ['AuthorizationError', 'EditPageSuccessResult', 'UserError'],
    EditPageTemplateResult: ['AuthorizationError', 'EditPageTemplateSuccessResult', 'UserError'],
    EditPlatformAdminUserResult: [
      'AuthorizationError',
      'EditPlatformAdminUserSuccessResult',
      'UserError',
    ],
    EditPlatformResult: ['EditPlatformSuccessResult', 'UserError'],
    EditProductResult: ['EditProductSuccessResult', 'UserError'],
    EditSequenceResult: ['EditSequenceSuccessResult', 'UserError'],
    EditSequenceUserResult: ['EditSequenceUserSuccessResult', 'UserError'],
    EditSiteResult: ['AuthorizationError', 'EditSiteSuccessResult', 'InternalError', 'UserError'],
    EditUserResult: ['EditUserSuccessResult', 'UserError'],
    Error: ['AuthorizationError', 'InternalError', 'UserError'],
    FindOrCreateStripeSubscriptionResult: [
      'FindOrCreateStripeSubscriptionActiveResult',
      'FindOrCreateStripeSubscriptionErrorResult',
      'FindOrCreateStripeSubscriptionIncompleteResult',
      'FindOrCreateStripeSubscriptionUpdatedResult',
    ],
    GenerateComponentsResult: [
      'GenerateComponentsAskSuccessResult',
      'GenerateComponentsDesignSuccessResult',
      'InternalError',
      'UserError',
    ],
    InvalidateApiTokenResult: ['InvalidateApiTokenSuccessResult', 'UserError'],
    ListResult: [
      'ActiveCampaignApiTagsList',
      'BuilderComponentsList',
      'ComponentsList',
      'CourseAndLessonLocationVersionsList',
      'CourseVersionsList',
      'CoursesList',
      'CustomFieldValuesList',
      'CustomFieldVersionsList',
      'CustomFieldsList',
      'CustomTableEditorFormVersionsList',
      'CustomTableVersionFieldsList',
      'CustomTableVersionsList',
      'CustomTablesList',
      'DocumentsList',
      'ExtensionVersionsList',
      'ExtensionsList',
      'FormEntriesList',
      'HelpTicketsList',
      'LayoutTemplatesList',
      'LayoutsList',
      'LessonVersionsList',
      'LessonsList',
      'OrgsList',
      'PageTemplatesList',
      'PageVersionsList',
      'PagesList',
      'PersonalApiTokensList',
      'PlatformAdminUsersList',
      'PlatformApiTokensList',
      'ProductItemPicklist',
      'ProductsList',
      'PromoCodePicklist',
      'PublicSitePagesList',
      'ReviewsList',
      'SequencesList',
      'SiteTemplatesList',
      'SitesList',
      'SubscriptionAuditLogsList',
      'SubscriptionsList',
      'UserAuditLogsList',
      'UserCourseProgressLessonsList',
      'UserCourseProgressesList',
      'UsersList',
    ],
    MoveUserToOrgResult: ['MoveUserToOrgSuccessResult', 'UserError'],
    PreviewStripeUpcomingInvoiceResult: [
      'PreviewStripeUpcomingInvoiceErrorResult',
      'PreviewStripeUpcomingInvoiceSuccessResult',
    ],
    RestoreCourseVersionChangesResult: [
      'AuthorizationError',
      'InternalError',
      'RestoreCourseVersionChangesSuccessResult',
      'UserError',
    ],
    RestoreLessonVersionChangesResult: [
      'AuthorizationError',
      'InternalError',
      'RestoreLessonVersionChangesSuccessResult',
      'UserError',
    ],
    RestorePageVersionChangesResult: [
      'AuthorizationError',
      'InternalError',
      'RestorePageVersionChangesSuccessResult',
      'UserError',
    ],
    SplitUserToNewOrgResult: ['SplitUserToNewOrgSuccessResult', 'UserError'],
    SyncLayoutTemplateToLayoutsResult: [
      'AuthorizationError',
      'SyncLayoutTemplateToLayoutsSuccessResult',
      'UserError',
    ],
    SyncPageTemplateToPagesResult: [
      'AuthorizationError',
      'SyncPageTemplateToPagesSuccessResult',
      'UserError',
    ],
    UploadFileResult: [
      'AuthorizationError',
      'InternalError',
      'UploadFileSuccessResult',
      'UserError',
    ],
  },
};
export default result;
