import { setUser as setSentryUser } from '@sentry/browser';
import { skipToken, useQuery, useSuspenseQuery } from '@wirechunk/apollo-client';
import { AdminMeDocument } from '@wirechunk/lib/shared-queries/admin-me-query.generated.ts';
import { MeDocument } from '@wirechunk/lib/shared-queries/me-query.generated.ts';
import {
  AnalyticsContext,
  CurrentAdminUserContext,
  CurrentUserContext,
  SessionStatus,
  triggerGoogleTagManagerEvent,
} from '@wirechunk/ui';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { use, useEffect, useMemo, useState } from 'react';
import { apolloClient } from '../apollo-client.ts';
import { useErrorHandler } from '../hooks/useErrorHandler.tsx';
import { useRefreshSession } from '../hooks/useRefreshSession/useRefreshSession.ts';
import { authenticateMessenger, signOutMessenger } from '../util/zendesk/zendesk.ts';

const { admin } = window.wirechunk;

export const SessionUserProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const analyticsContext = use(AnalyticsContext);
  const [sessionStatus, setSessionStatus] = useState<SessionStatus>(SessionStatus.SignedOut);
  const [sessionErrorMessage, setSessionErrorMessage] = useState<string | null>(null);
  const { onErrorToast } = useErrorHandler();
  const { data: meData, loading } = useQuery(MeDocument, { onError: onErrorToast });
  const { data: adminMeData } = useSuspenseQuery(AdminMeDocument, admin ? undefined : skipToken);
  const me = meData?.me || null;
  const currentUserContext = useMemo<CurrentUserContext>(
    () => ({
      user: me,
      loading,
      status: sessionStatus,
      setStatus: (status) => {
        setSessionStatus(status);
        if (status === SessionStatus.SignedOut || status === SessionStatus.Expired) {
          analyticsContext.reset();
          signOutMessenger();
          setSentryUser(null);
        }
      },
      errorMessage: sessionErrorMessage,
      setErrorMessage: setSessionErrorMessage,
    }),
    [me, loading, sessionStatus, sessionErrorMessage, analyticsContext],
  );
  useRefreshSession();

  useEffect(() => {
    if (me) {
      authenticateMessenger(apolloClient);
      // An event with just variables makes these variables defined for all future events.
      triggerGoogleTagManagerEvent({
        user_id: me.id,
        user_email: me.email,
        user_first_name: me.firstName,
        user_last_name: me.lastName,
        user_display_name: me.displayName,
        user_role: me.role,
        user_product_items: me.productItems,
      });
      triggerGoogleTagManagerEvent({
        event: 'user_authenticated',
      });
      setSentryUser({
        id: me.id,
        email: me.email,
      });
      setSessionStatus(SessionStatus.Active);
    }
  }, [me, setSessionStatus]);

  return (
    <CurrentUserContext value={currentUserContext}>
      {admin ? (
        <CurrentAdminUserContext value={adminMeData?.adminMe ?? null}>
          {children}
        </CurrentAdminUserContext>
      ) : (
        children
      )}
    </CurrentUserContext>
  );
};
